// scroll bar

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Loadable from "react-loadable";
import ContentSettingState from "./Hooks/ContentContext/ContentSettingState";

//
const App = Loadable({
  loader: () => import(/*webpackChunkName: "rcs_App"*/ "./App"),
  loading() {
    return (
      <></>
      // <CircularProgress
      //   sx={{ marginLeft: "50%", marginTop: "20%" }}
      //   color="primary"
      // />
    );
  },
});
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
// import "bootstrap/dist/css/bootstrap.min.css";

// ----------------------------------------------------------------------

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <ContentSettingState>
        <App />
      </ContentSettingState>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById("root")
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
